import { getFacture, getFactures, restoreRejet, saveRejet, getMotifExclusion, getRemboursements, getRemboursement, updateRemboursement, requestRemboursement } from '../../services/FacturationService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';
import uploadFiles from '../../services/BlobStorageService';


export const GET_FACTURES_CONFIRMED_ACTION = '[getFactures action] confirmed getFactures';
export const GET_FACTURES_FAILED_ACTION = '[getFactures action] failed getFactures';

export const GET_FACTURE_CONFIRMED_ACTION = '[getFacture action] confirmed getFacture';
export const GET_FACTURE_FAILED_ACTION = '[getFacture action] failed getFacture';

export const GET_MOTIFS_EXCLU_CONFIRMED_ACTION = '[getMotifExclu action] confirmed getMotifExclu';
export const GET_MOTIFS_EXCLU_FAILED_ACTION = '[getMotifExclu action] failed getMotifExclu';

export const SAVE_REJET_FACTURE_CONFIRMED_ACTION = '[saveRejetFacture action] confirmed saveRejetFacture';
export const SAVE_REJET_FACTURE_FAILED_ACTION = '[saveRejetFacture action] failed saveRejetFacture';

export const RESTORE_REJET_FACTURE_CONFIRMED_ACTION = '[restoreRejetFacture action] confirmed restoreRejetFacture';
export const RESTORE_REJET_FACTURE_FAILED_ACTION = '[restoreRejetFacture action] failed restoreRejetFacture';

export const REQUEST_REMBOURSEMENT_CONFIRMED_ACTION = '[requestRemboursement action] confirmed requestRemboursement';
export const REQUEST_REMBOURSEMENT_FAILED_ACTION = '[requestRemboursement action] failed requestRemboursement';

export const GET_REMBOURSEMENTS_CONFIRMED_ACTION = '[getRemboursements action] confirmed getRemboursements';
export const GET_REMBOURSEMENTS_FAILED_ACTION = '[getRemboursements action] failed getRemboursements';

export const GET_REMBOURSEMENT_CONFIRMED_ACTION = '[getRemboursement action] confirmed getRemboursement';
export const GET_REMBOURSEMENT_FAILED_ACTION = '[getRemboursement action] failed getRemboursement';

export const UPDATE_REMBOURSEMENT_CONFIRMED_ACTION = '[updateRemboursement action] confirmed updateRemboursement';
export const UPDATE_REMBOURSEMENT_FAILED_ACTION = '[updateRemboursement action] failed updateRemboursement';

export const START_LOADER_ACTION = '[providerLoader action] started providerLoader';

var I18n = require('react-redux-i18n').I18n;

export const getFacturesAction = (assureId) => {
   
   return (dispatch) => {
    getFactures(assureId)
       .then((response) => {
           
           dispatch(confirmedGetFacturesAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
           dispatch(getFacturesFailedAction(error.response?.data?.message));
       });
   };
};

export const getFactureAction = (id) => {
   
   return (dispatch) => {
    getFacture(id)
       .then((response) => {
           
           dispatch(confirmedGetFactureAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.getFactures.error_single')+error.response?.data?.message);
           dispatch(getFactureFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};

export const getMotifsExclusionAction = () => {
    
    return (dispatch) => {
        getMotifExclusion()
        .then((response) => {
            
            dispatch(confirmedGetMotifsExclusionAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getMotifs.error')+error.response?.data?.message);
            dispatch(getMotifsExclusionFailedAction(error.response?.data?.message));
        });
    };
 };


export const saveRejetAction = (id, data) => {
   
   return (dispatch) => {
    saveRejet(id, data)
       .then((response) => {
           
           successToastr(I18n.t('factures.rejet.save_success'));
           dispatch(confirmedSaveRejetFactureAction());
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.rejet.save_error')+error.response?.data?.message);
           dispatch(saveRejetFactureFailedAction(error.response?.data?.message));
       });
   };
}

export const restoreRejetAction = (id) => {
    
    return (dispatch) => {
        restoreRejet(id)
        .then((response) => {
            
            successToastr(I18n.t('factures.rejet.restore_success'));
            dispatch(confirmedRestoreRejetFactureAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rejet.restore_error')+error.response?.data?.message);
            dispatch(restoreRejetFactureFailedAction(error.response?.data?.message));
        });
    };
 }


 export const requestRemboursementAction = (data, history) => {
    
    return async (dispatch) => {
        for(let i=0; i<data.ligneAssures.length; i++) {
            for(let k=0; k<data.ligneAssures[i].benefList.length; k++) {
                for(let l=0; l<data.ligneAssures[i].benefList[k].remboursements.length; l++){
                    let billRemFiles = await uploadFiles(data.ligneAssures[i].benefList[k].remboursements[l].files, 'amp-providers-container');
                    data.ligneAssures[i].benefList[k].remboursements[l].files = billRemFiles;
                    for(let j=0; j<data.ligneAssures[i].benefList[k].remboursements[l].details.length; j++){
                        let billFiles = await uploadFiles(data.ligneAssures[i].benefList[k].remboursements[l].details[j].files, 'amp-providers-container');
                        data.ligneAssures[i].benefList[k].remboursements[l].details[j].files = billFiles;
                    }
                }
            }
        }
        requestRemboursement(data)
        .then((response) => {
            
            successToastr(I18n.t('factures.rembours.request_success'));
            dispatch(confirmedRequestRemboursementAction());
            history.push('/remboursement-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.request_error')+error.response?.data?.message);
            dispatch(requestRemboursementFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getRemboursementsAction = (id) => {
    
    return (dispatch) => {
     getRemboursements(id)
        .then((response) => {
            
            dispatch(confirmedGetRemboursementsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.gets_error')+error.response?.data?.message);
            dispatch(getRemboursementsFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getRemboursementAction = (id) => {
    
    return (dispatch) => {
     getRemboursement(id)
        .then((response) => {
            
            dispatch(confirmedGetRemboursementAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.get_error')+error.response?.data?.message);
            dispatch(getRemboursementFailedAction(error.response?.data?.message));
        });
    };
 };

 export const updateRemboursementAction = (id, data, history) => {
    
    return async (dispatch) => {
        updateRemboursement(id, data)
        .then((response) => {
            
            successToastr(I18n.t('factures.rembours.update_success'));
            dispatch(confirmedUpdateRemboursementAction(response.data));
            history.push('/remboursement-details/'+id);
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.update_error')+error.response?.data?.message);
            dispatch(updateRemboursementFailedAction(error.response?.data?.message));
        });
    };
 };

 export function confirmedUpdateRemboursementAction(payload) {
    return {
        type: UPDATE_REMBOURSEMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateRemboursementFailedAction(payload) {
    return {
        type: UPDATE_REMBOURSEMENT_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedGetRemboursementAction(payload) {
    return {
        type: GET_REMBOURSEMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getRemboursementFailedAction(payload) {
    return {
        type: GET_REMBOURSEMENT_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedGetRemboursementsAction(payload) {
    return {
        type: GET_REMBOURSEMENTS_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getRemboursementsFailedAction(payload) {
    return {
        type: GET_REMBOURSEMENTS_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedRequestRemboursementAction(payload) {
    return {
        type: REQUEST_REMBOURSEMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function requestRemboursementFailedAction(payload) {
    return {
        type: REQUEST_REMBOURSEMENT_FAILED_ACTION,
        payload,
    };
 };

export function confirmedGetFacturesAction(payload) {
   return {
       type: GET_FACTURES_CONFIRMED_ACTION,
       payload,
   };
};

export function getFacturesFailedAction(payload) {
   return {
       type: GET_FACTURES_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetFactureAction(payload) {
   return {
       type: GET_FACTURE_CONFIRMED_ACTION,
       payload,
   };
};

export function getFactureFailedAction(payload) {
   return {
       type: GET_FACTURE_FAILED_ACTION,
       payload,
   };
};


export function confirmedGetMotifsExclusionAction(payload) {
    return {
        type: GET_MOTIFS_EXCLU_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getMotifsExclusionFailedAction(payload) {
    return {
        type: GET_MOTIFS_EXCLU_FAILED_ACTION,
        payload,
    };
 };

export function startLoader() {
   return {
       type: START_LOADER_ACTION
   };
};

export function confirmedSaveRejetFactureAction() {
   return {
       type: SAVE_REJET_FACTURE_CONFIRMED_ACTION
   };
};

export function saveRejetFactureFailedAction(payload) {
   return {
       type: SAVE_REJET_FACTURE_FAILED_ACTION,
       payload,
   };
};

export function confirmedRestoreRejetFactureAction() {
    return {
        type: RESTORE_REJET_FACTURE_CONFIRMED_ACTION
    };
 };
 
 export function restoreRejetFactureFailedAction(payload) {
    return {
        type: RESTORE_REJET_FACTURE_FAILED_ACTION,
        payload,
    };
 };