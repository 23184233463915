import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import Select from 'react-select';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getRemboursementAction, requestRemboursementAction, startLoader, updateRemboursementAction } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Button, Spinner, ListGroup } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { listProvidersAction } from '../../../../store/actions/ProviderActions';
import { getBeneficiairesAction, getAssuresBeneficiairesAction, getSouscripteursAction, getGarantiesAction, searchContratAction, loadingToggleAction } from "../../../../store/actions/ContratActions";
import TextInput from 'react-autocomplete-input';
import PerfectScrollbar from "react-perfect-scrollbar";


export const RemboursementRequest = (props) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const { history, showLoading, providers, beneficiaires, remboursement, garanties, currentUser } = props;

    const [searchKey, setSearchKey] = useState('');
    const [choosenContrat, setChoosenContrat] = useState(currentUser.police);
    const [souscriptId, setSouscriptId] = useState(currentUser.souscripteur.id);
    const [souscriptName, setSouscriptName] = useState(currentUser.souscripteur.nom_Assu+' '+currentUser.souscripteur.prenAssu);
    const [numeroPolice, setNumeroPolice] = useState(currentUser.police.numeroPolice);
    const [consultationGarantie, setConsultationGarantie] = useState({});
    const [ligneAssures, setLigneAssures] = useState([
        {   
            assurePrincId: "",
            assurePrincName: "",
            montantReclame: 0,
            benefList: [   
                {
                    assureId: "",
                    assureName: "",
                    montantReclame: 0,
                    remboursements: [
                        {
                            prestatairePrescriptId: "",
                            prestatairePrescriptName: "",
                            prestataireExecId: "",
                            prestataireExecName: "",
                            categorieId: "",
                            categorieName: "",
                            montant: 0,
                            medecin: "",
                            date: new Date(),
                            files: [],
                            details: [
                                {
                                    prestataireExecId: "",
                                    prestataireExecName: "",
                                    categorieId: "",
                                    categorieName: "",
                                    montant: 0,
                                    date: new Date(),
                                    files: []
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);

    const saveFiles = (e, i, j, k, m = null) => {
        
        let upFiles = e.target.files;
        const fileList = [];
        const keys = Object.keys(upFiles);
        for (let i = 0; i < keys.length; i += 1) {
            fileList.push(upFiles[keys[i]]);
        }

        if(m!==null) {
            setLigneAssures(ligneAssures.map((linA, l) => {
                if(l === i) {
                    linA.benefList[j].remboursements[k].details[m].files = fileList;
                }
                return linA;
            }))
        }else{
            setLigneAssures(ligneAssures.map((linA, l) => {
                if(l === i) {
                    linA.benefList[j].remboursements[k].files = fileList;
                }
                return linA;
            }))
        }
    }

    const requestRemboursement = (e) => {
        e.preventDefault();
        dispatch(startLoader());

        let data = {
            souscriptId,
            souscriptName,
            numeroPolice,
            ligneAssures
        }


        if(id) {
            dispatch(updateRemboursementAction(id, data, history));
        }else{
            dispatch(requestRemboursementAction(data, history));
        }
    }

    const addLine = (e) => {
        e.preventDefault();
        setLigneAssures([...ligneAssures, {   
            assurePrincId: "",
            assurePrincName: "",
            montantReclame: 0,
            benefList: [   
                {
                    assureId: "",
                    assureName: "",
                    montantReclame: 0,
                    remboursements: [
                        {
                            prestatairePrescriptId: "",
                            prestatairePrescriptName: "",
                            prestataireExecId: "",
                            prestataireExecName: "",
                            categorieId: consultationGarantie.id,
                            categorieName: consultationGarantie.libelle,
                            montant: 0,
                            medecin: "",
                            date: new Date(),
                            files: [],
                            details: [
                                {
                                    prestataireExecId: "",
                                    prestataireExecName: "",
                                    categorieId: "",
                                    categorieName: "",
                                    montant: 0,
                                    date: "",
                                    files: []
                                }
                            ]
                        }
                    ]
                }
            ]
        }])
    }

    const calculateSums = (l, j) => {
        let assure = ligneAssures[l];
        let benef = ligneAssures[l].benefList[j];
        benef.montantReclame = benef.remboursements.reduce((sum, remb) => {
            return sum + parseInt(remb.montant) + remb.details.reduce((subSum, detail) => subSum + parseInt(detail.montant), 0)
        }, 0);

        assure.montantReclame = assure.benefList.reduce((sumT, ben) => sumT + ben.montantReclame, 0);

        assure.benefList[j] = benef;

        setLigneAssures((line, i) => {
            if(i === l){
                line = assure;
            }
            return line
        })
    }

    const addPreSubLine = (e, i) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, j) => {
            if(j === i) {
                assure.benefList = [...assure.benefList, {
                    assureId: "",
                    assureName: "",
                    montantReclame: 0,
                    remboursements: [
                        {
                            prestatairePrescriptId: "",
                            prestatairePrescriptName: "",
                            prestataireExecId: "",
                            prestataireExecName: "",
                            categorieId: consultationGarantie.id,
                            categorieName: consultationGarantie.libelle,
                            montant: 0,
                            medecin: "",
                            date: new Date(),
                            files: [],
                            details: [
                                {
                                    prestataireExecId: "",
                                    prestataireExecName: "",
                                    categorieId: "",
                                    categorieName: "",
                                    montant: 0,
                                    date: new Date(),
                                    files: []
                                }
                            ]
                        }
                    ]
                }];
            }
            return assure;
        }))
    }

    const addSubLine = (e, i, j) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if(k === i) {
                assure.benefList[j].remboursements = [...assure.benefList[j].remboursements,  {
                    prestatairePrescriptId: "",
                    prestatairePrescriptName: "",
                    prestataireExecId: "",
                    prestataireExecName: "",
                    categorieId: consultationGarantie.id,
                    categorieName: consultationGarantie.libelle,
                    montant: 0,
                    medecin: "",
                    date: new Date(),
                    files: [],
                    details: [
                        {
                            prestataireExecId: "",
                            prestataireExecName: "",
                            categorieId: "",
                            categorieName: "",
                            montant: 0,
                            date: new Date(),
                            files: []
                        }
                    ]
                }];
            }
            return assure;
        }))
    }

    const addDetailsLine = (e, i, j, k) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, l) => {
            if(l === i) {
                assure.benefList[j].remboursements[k].details = [...assure.benefList[j].remboursements[k].details, {
                    prestataireExecId: "",
                    prestataireExecName: "",
                    categorieId: "",
                    categorieName: "",
                    montant: 0,
                    date: new Date(),
                    files: []
                }];
            }
            return assure;
        }))
    }

    const removeLine = (e, i) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.filter((assure, j) => {return j!==i}));
    }

    

    const removePreSubLine = (e, l, i) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if(k === l) {
                assure.benefList = assure.benefList.filter((detail, j) => {return j!==i})
            }
            return assure;
        }));
    }

    const removeSubLine = (e, l, i, p) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if(k === l) {
                assure.benefList[i].remboursements = assure.benefList[i].remboursements.filter((detail, j) => {return j!==p})
            }
            return assure;
        }));
    }

    const removeDetailsLine = (e, l, i, p, o) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if(k === l) {
                assure.benefList[i].remboursements[p].details = assure.benefList[i].remboursements[p].details.filter((detail, j) => {return j!==o})
            }
            return assure;
        }));
    }

    const findBenefs = (numPolice) => {
        dispatch(getBeneficiairesAction(numPolice));
    }

    const getChoosenAssures = () => {
        let res = ligneAssures.map(ligne => {
            return ligne.assurePrincId
        });
        return res;
    }

    const getChoosenBeneficiaires = () => {
        let res = ligneAssures.map(ligne => {
            return ligne.benefList.map(line => {
                return line.assureId
            })
        });
        return res;
    }

    const startSearch = (e) => {
        e.preventDefault();
        dispatch(loadingToggleAction());
        dispatch(getRemboursementAction(searchKey));
    }

    useEffect(() => {
        dispatch(getBeneficiairesAction(currentUser.police.numeroPolice));
        if(id) {
            dispatch(getRemboursementAction(id));
        }
        dispatch(listProvidersAction());
        dispatch(getGarantiesAction());
    }, []);

    useEffect(() => {
        if(Object.keys(remboursement).length > 0) {
            setSouscriptId(remboursement.souscripteurId);
            setSouscriptName(remboursement.souscripteurName);

            let rembDetails = remboursement.factureRemboursementDetails;

            let res = [];

            let visitedIds = [];

            let myRes = rembDetails.map(remb => {
                let obj = {};
                if(!visitedIds.includes(remb.assurerPrincipalId)){
                   obj.assurePrincId = remb.assurerPrincipalId;
                   obj.assurePrincName = remb.assurerPrincipalName;
                   obj.montantReclame = remb.montantReclame;
                   let benefs = rembDetails.filter(elt => {return elt.assurerPrincipalId === remb.assurerPrincipalId});
                   
                   let visitedBenefIds = [];
                   obj.benefList = benefs.map(benef => {
                        let res = {};
                        if(!visitedBenefIds.includes(benef.assurerId)){
                            res.assureId = benef.assurerId;
                            res.assureName = benef.assurerName;
                            res.montantReclame = benef.montantReclame;
                            let presta = rembDetails.filter(elt => {return elt.assurerId === benef.assurerId});
                            
                            res.remboursements  = presta.map(prest => {
                                let resP = {};
                                if(prest.type && prest.type.toLowerCase().includes("consultation")) {
                                    resP.id = prest.id;
                                    resP.consultationCode = prest.consultationCode;
                                    resP.prestatairePrescriptId = prest.prescripteurId;
                                    resP.prestatairePrescriptName = prest.prescripteurName;
                                    resP.prestataireExecId = prest.executeurId;
                                    resP.prestataireExecName = prest.executeurName;
                                    resP.categorieId = prest.categorieId;
                                    resP.categorieName = prest.categorieName;
                                    resP.montant = prest.montantPrestationReclame;
                                    resP.medecin = prest.prescripteurName;
                                    resP.date = new Date(prest.dateExecution);
                                    resP.files = [prest.files];
                                    let detPrest = rembDetails.filter(elt => {return (elt.consultationCode === prest.consultationCode && !elt.type)})
                                    
                                    resP.details = detPrest.map(detP => {
                                        return {
                                            id: detP.id,
                                            consultationCode: detP.consultationCode,
                                            prestataireExecId: detP.executeurId,
                                            prestataireExecName: detP.executeurName,
                                            categorieId: detP.categorieId,
                                            categorieName: detP.categorieName,
                                            montant: detP.montantPrestationReclame,
                                            date: new Date(detP.dateExecution),
                                            files: [detP.files]
                                        }
                                    });
                                }
                                return resP;
                            });
                            res.remboursements = res.remboursements.filter(elt => {return Object.keys(elt).length > 0});
                            visitedBenefIds.push(benef.assurerId);
                        }
                        return res;
                   });
                   obj.benefList = obj.benefList.filter(elt => {return Object.keys(elt).length > 0});
                   visitedIds.push(remb.assurerPrincipalId);
                }
                return obj;
            });


            setLigneAssures(myRes.filter(elt => {return Object.keys(elt).length > 0}));
        }
    }, [remboursement]);

    useEffect(() => {
        if(garanties.length > 0) {
            let consultation = garanties.filter(garantie => {return garantie.code === "2FC"})[0];
            setConsultationGarantie(consultation);
            let datas = ligneAssures;
            datas[0].benefList[0].remboursements[0].categorieId = consultation.id;
            datas[0].benefList[0].remboursements[0].categorieName = consultation.libelle;
            setLigneAssures(datas);
        }
    }, [garanties, choosenContrat]);

    return (
        <Fragment>
           <div className="row">
              <div className="col-xl-12 col-xxl-12">
                 <div className="card">
                    <div className="card-header">
                       <h4 className="card-title">Demander un remboursement</h4>
                    </div>
                    <div className="card-body">
                            {!currentUser.isSouscripteur && <form onSubmit={(e) => startSearch(e)}>
                                <p>Rechercher la demande ouverte</p>
                                <div className="row">
                                    <div className="col-lg-6 mb-2">
                                        <div className="form-group">
                                            <input type='text' className='form-control'
                                                value={searchKey}
                                                placeholder="numéro du remboursement"
                                                required
                                                onChange={(e) => {
                                                    setChoosenContrat({});
                                                    setSouscriptId('');
                                                    setSouscriptName('');
                                                    setLigneAssures([
                                                        {   
                                                            assurePrincId: "",
                                                            assurePrincName: "",
                                                            montantReclame: 0,
                                                            benefList: [   
                                                                {
                                                                    assureId: "",
                                                                    assureName: "",
                                                                    montantReclame: 0,
                                                                    remboursements: [
                                                                        {
                                                                            prestatairePrescriptId: "",
                                                                            prestatairePrescriptName: "",
                                                                            prestataireExecId: "",
                                                                            prestataireExecName: "",
                                                                            categorieId: "",
                                                                            categorieName: "",
                                                                            montant: 0,
                                                                            medecin: "",
                                                                            date: new Date(),
                                                                            files: [],
                                                                            details: [
                                                                                {
                                                                                    prestataireExecId: "",
                                                                                    prestataireExecName: "",
                                                                                    categorieId: "",
                                                                                    categorieName: "",
                                                                                    montant: 0,
                                                                                    date: new Date(),
                                                                                    files: []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ])
                                                    setSearchKey(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    { showLoading && 
                                        <div className='col-6'>
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">Un instant...</span>
                                            </Button> 
                                        </div>}
                                    { !showLoading &&
                                        <div className='col-1'>
                                            <button type='submit' className='btn btn-primary btn-block'>
                                                <i className='la la-search'></i>
                                            </button>
                                        </div> }
                                </div>
                            </form>}
                            {((!currentUser.isSouscripteur && Object.keys(remboursement).length > 0) || currentUser.isSouscripteur) && <>
                                <h5 className='mt-4 text-primary'>Demande de Remboursement</h5>
                                <div className="border-top mt-2 mb-4"></div>
                                <div>
                                    <p><b>Souscripteur: </b>{souscriptName}</p>
                                    <p><b>N° Police: </b>{numeroPolice}</p>
                                    <p><b>Date d'effet: </b>{choosenContrat.dateEffet}</p>
                                    <p><b>Date d'expiration: </b>{choosenContrat.dateExpiration}</p>
                                    {(new Date() > new Date(choosenContrat.dateExpiration))  && <p>
                                        <b className="text-danger">
                                            Votre police est expirée et n'a pas été renouvelée, 
                                            vous ne pouvez demander de remboursement
                                        </b>
                                    </p>}
                                </div>
                                <div className="border-top mt-2 mb-4"></div>
                                <form
                                    onSubmit={(e) => requestRemboursement(e)}
                                >
                                        <section>
                                            {ligneAssures.map((ligne, i) => {
                                                return (currentUser.isSouscripteur) ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-lg-5 mb-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Assuré principal</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={beneficiaires.filter(cat => {return cat.assurePrinc.id === ligne.assurePrincId})
                                                                    .map(elt => {return {label: elt.assurePrinc.nom+' '+elt.assurePrinc.prenoms, value: elt.assurePrinc.id}})}
                                                                    options={beneficiaires.map(ass => {return {label: ass.assurePrinc.nom+' '+ass.assurePrinc.prenoms, value: ass.assurePrinc.id}}).filter(elt => {return !getChoosenAssures().includes(elt.value)})}
                                                                    placeholder="Choisir l'assuré principal"
                                                                    search
                                                                    isRequired
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, j) => {
                                                                            if(i === j) {
                                                                                linA.assurePrincId = val.value;
                                                                                linA.assurePrincName = val.label;
                                                                            }
                                                                            return linA;
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 mb-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Montant total</strong>
                                                                </label>
                                                                <input type='number' className='form-control'
                                                                    value={ligne.montantReclame}
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 mb-2">
                                                            <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeLine(e, i)}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {ligne.benefList.map((line, j) => {
                                                        return <>
                                                        <div className="row ml-2" key={j}>
                                                            <div className="col-lg-5 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Bénéficiaire</strong>
                                                                    </label>
                                                                    <Select
                                                                        className="select-search"
                                                                        value={beneficiaires.filter(assur => {return assur.assurePrinc.id === ligne.assurePrincId})[0]?.beneficiaires.filter(cat => {return cat.id === line.assureId})
                                                                        .map(elt => {return {label: elt.nom+' '+elt.prenoms, value: elt.id}})}
                                                                        options={beneficiaires.filter(assur => {return assur.assurePrinc.id === ligne.assurePrincId})[0]?.beneficiaires.map(benef => {
                                                                            return {label: benef.nom+' '+benef.prenoms, value: benef.id}
                                                                        }).filter(elt => {return !getChoosenBeneficiaires().includes(elt.value)})}
                                                                        placeholder="Choisir l'assuré"
                                                                        search
                                                                        isRequired
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, k) => {
                                                                                if(i === k) {
                                                                                    linA.benefList[j].assureId = val.value;
                                                                                    linA.benefList[j].assureName = val.label;
                                                                                }
                                                                                return linA;
                                                                            }));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Montant total</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={ligne.benefList[j].montantReclame}
                                                                        required
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 mb-2">
                                                                {j > 0 && <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                    onClick={(e) => removePreSubLine(e, i, j)}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>}
                                                            </div>
                                                        </div>
                                                        
                                                        {line.remboursements.map((remboursement, k) => {
                                                            return <>
                                                            <h6 className="ml-4 text-secondary">REMBOURSEMENT N°{" "}{i+1}{j+1}{k+1}</h6>
                                                            <div className="border-top border-secondary my-2 ml-4"></div>
                                                            <div className="row mb-2 ml-2" key={k}>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Categorie</strong>
                                                                        </label>
                                                                        <Select
                                                                            className="select-search"
                                                                            value={garanties.filter(cat => {return cat.id === remboursement.categorieId})
                                                                            .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                            options={garanties.map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                            placeholder="Choisir"
                                                                            isDisabled
                                                                            search
                                                                            isRequired
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].categorieId = val.value;
                                                                                        linA.benefList[j].remboursements[k].categorieName = val.label;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Centre prescripteur</strong>
                                                                        </label>
                                                                        <TextInput
                                                                            className='form-control custom-text-input border'
                                                                            Component="input"
                                                                            required
                                                                            trigger={""}
                                                                            value={remboursement.prestatairePrescriptName}
                                                                            placeholder="Entrer un prestataire"
                                                                            onChange={val => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].prestatairePrescriptName = val;
                                                                                        let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                        linA.benefList[j].remboursements[k].prestatairePrescriptId = provider ? provider.id : null;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            options={providers.map(prov => {return prov.providerName})}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Centre exécutant</strong>
                                                                        </label>
                                                                        <TextInput
                                                                            className='form-control custom-text-input border'
                                                                            Component="input"
                                                                            required
                                                                            trigger={""}
                                                                            value={remboursement.prestataireExecName}
                                                                            placeholder="Entrer un prestataire"
                                                                            onChange={val => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].prestataireExecName = val;
                                                                                        let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                        linA.benefList[j].remboursements[k].prestataireExecId = provider ? provider.id : null;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            options={providers.map(prov => {return prov.providerName})}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Médecin</strong>
                                                                        </label>
                                                                        <input type='text' className='form-control'
                                                                            value={remboursement.medecin}
                                                                            required
                                                                            onChange={(e) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].medecin = e.target.value;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            />
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Montant réclamé</strong>
                                                                        </label>
                                                                        <input type='number' className='form-control'
                                                                            value={remboursement.montant}
                                                                            required
                                                                            onChange={(e) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].montant = parseInt(e.target.value);
                                                                                        calculateSums(l, j);
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Date de soin</strong>
                                                                        </label>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <DatePicker
                                                                                autoOk
                                                                                label=""
                                                                                clearable
                                                                                format="dd/MM/yyyy hh:mm"
                                                                                value={remboursement.date}
                                                                                disableFuture
                                                                                required
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].date = val;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Fichiers({remboursement.files.length} chargés)</strong>
                                                                        </label>
                                                                        <div className='input-group'>
                                                                            <div className='custom-file'>
                                                                                <input type='file' multiple onChange={(e) => saveFiles(e, i, j, k)} className='custom-file-input' />
                                                                                <label className='custom-file-label'>Ajouter</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {k > 0 && <div className="col-lg-1">
                                                                    <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                        onClick={(e) => removeSubLine(e, i, j, k)}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </button>
                                                                </div>}
                                                        </div>
                                                        
                                                        {remboursement.details.map((detail, m) => {
                                                            return <div className="row mb-2 ml-4" key={m}>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Categorie</strong>
                                                                        </label>
                                                                        <Select
                                                                            className="select-search"
                                                                            value={garanties.filter(cat => {return cat.id === detail.categorieId})
                                                                            .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                            options={garanties.filter(cat => {return cat.id !== remboursement.categorieId})
                                                                            .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                            placeholder="Choisir"
                                                                            search
                                                                            isRequired
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].details[m].categorieId = val.value;
                                                                                        linA.benefList[j].remboursements[k].details[m].categorieName = val.label;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Centre exécutant</strong>
                                                                        </label>
                                                                        <TextInput
                                                                            className='form-control custom-text-input border'
                                                                            Component="input"
                                                                            required
                                                                            trigger={""}
                                                                            value={detail.prestataireExecName}
                                                                            placeholder="Entrer un prestataire"
                                                                            onChange={val => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].details[m].prestataireExecName = val;
                                                                                        let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                        linA.benefList[j].remboursements[k].details[m].prestataireExecId = provider ? provider.id : null;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            options={providers.map(prov => {return prov.providerName})}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Montant réclamé</strong>
                                                                        </label>
                                                                        <input type='number' className='form-control'
                                                                            value={detail.montant}
                                                                            required
                                                                            onChange={(e) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if(i === l) {
                                                                                        linA.benefList[j].remboursements[k].details[m].montant = e.target.value;
                                                                                        calculateSums(l, j);
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Date de soin</strong>
                                                                        </label>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <DatePicker
                                                                                autoOk
                                                                                label=""
                                                                                clearable
                                                                                format="dd/MM/yyyy hh:mm"
                                                                                value={detail.date}
                                                                                disableFuture
                                                                                required
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].details[m].date = val;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Fichiers({detail.files.length} chargés)</strong>
                                                                        </label>
                                                                        <div className='input-group'>
                                                                            <div className='custom-file'>
                                                                                <input type='file' multiple onChange={(e) => saveFiles(e, i, j, k, m)} className='custom-file-input' />
                                                                                <label className='custom-file-label'>Ajouter</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1">
                                                                    <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                        onClick={(e) => removeDetailsLine(e, i, j, k, m)}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </button>
                                                                </div>
                                                        </div>})}
                                                        
                                                        <div className="d-flex justify-content-end">
                                                            <Button variant="danger" onClick={e => addDetailsLine(e, i, j, k)}>
                                                                <i className="la la-plus"></i>
                                                            </Button>
                                                        </div>
                                                        <div className="border-top mt-2 mb-4"></div>
                                                        </>
                                                        })
                                                    }
                                                    <div className="d-flex justify-content-end">
                                                        <Button variant="secondary" onClick={e => addSubLine(e, i, j)}>
                                                            Ajouter un remboursement
                                                        </Button>
                                                    </div>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    </>
                                                    })}
                                                    
                                                    <div className="d-flex justify-content-end">
                                                        <Button variant="secondary" onClick={e => addPreSubLine(e, i)}>
                                                            Ajouter un bénéficiaire
                                                        </Button>
                                                    </div>
                                                    <div className="border-top border-primary mt-2 mb-4"></div>
                                                </> 
                                                : 
                                                (
                                                    (ligne.ligneAssures === currentUser.id) ?
                                                    <>
                                                        <div className="row">
                                                            <div className="col-lg-5 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Assuré principal</strong>
                                                                    </label>
                                                                    <Select
                                                                        className="select-search"
                                                                        value={beneficiaires.filter(cat => {return cat.assurePrinc.id === ligne.assurePrincId})
                                                                        .map(elt => {return {label: elt.assurePrinc.nom+' '+elt.assurePrinc.prenoms, value: elt.assurePrinc.id}})}
                                                                        options={beneficiaires.map(ass => {return {label: ass.assurePrinc.nom+' '+ass.assurePrinc.prenoms, value: ass.assurePrinc.id}}).filter(elt => {return !getChoosenAssures().includes(elt.value)})}
                                                                        placeholder="Choisir l'assuré principal"
                                                                        search
                                                                        isRequired
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, j) => {
                                                                                if(i === j) {
                                                                                    linA.assurePrincId = val.value;
                                                                                    linA.assurePrincName = val.label;
                                                                                }
                                                                                return linA;
                                                                            }));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Montant total</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={ligne.montantReclame}
                                                                        required
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 mb-2">
                                                                <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                    onClick={(e) => removeLine(e, i)}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {ligne.benefList.map((line, j) => {
                                                            return <>
                                                            <div className="row ml-2" key={j}>
                                                                <div className="col-lg-5 mb-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Bénéficiaire</strong>
                                                                        </label>
                                                                        <Select
                                                                            className="select-search"
                                                                            value={beneficiaires.filter(assur => {return assur.assurePrinc.id === ligne.assurePrincId})[0]?.beneficiaires.filter(cat => {return cat.id === line.assureId})
                                                                            .map(elt => {return {label: elt.nom+' '+elt.prenoms, value: elt.id}})}
                                                                            options={beneficiaires.filter(assur => {return assur.assurePrinc.id === ligne.assurePrincId})[0]?.beneficiaires.map(benef => {
                                                                                return {label: benef.nom+' '+benef.prenoms, value: benef.id}
                                                                            }).filter(elt => {return !getChoosenBeneficiaires().includes(elt.value)})}
                                                                            placeholder="Choisir l'assuré"
                                                                            search
                                                                            isRequired
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, k) => {
                                                                                    if(i === k) {
                                                                                        linA.benefList[j].assureId = val.value;
                                                                                        linA.benefList[j].assureName = val.label;
                                                                                    }
                                                                                    return linA;
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-5 mb-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Montant total</strong>
                                                                        </label>
                                                                        <input type='number' className='form-control'
                                                                            value={ligne.benefList[j].montantReclame}
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    {j > 0 && <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                        onClick={(e) => removePreSubLine(e, i, j)}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </button>}
                                                                </div>
                                                            </div>
                                                            
                                                            {line.remboursements.map((remboursement, k) => {
                                                                return <>
                                                                <h6 className="ml-4 text-secondary">REMBOURSEMENT N°{" "}{i+1}{j+1}{k+1}</h6>
                                                                <div className="border-top border-secondary my-2 ml-4"></div>
                                                                <div className="row mb-2 ml-2" key={k}>
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Categorie</strong>
                                                                            </label>
                                                                            <Select
                                                                                className="select-search"
                                                                                value={garanties.filter(cat => {return cat.id === remboursement.categorieId})
                                                                                .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                                options={garanties.map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                                placeholder="Choisir"
                                                                                isDisabled
                                                                                search
                                                                                isRequired
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].categorieId = val.value;
                                                                                            linA.benefList[j].remboursements[k].categorieName = val.label;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Centre prescripteur</strong>
                                                                            </label>
                                                                            <TextInput
                                                                                className='form-control custom-text-input border'
                                                                                Component="input"
                                                                                required
                                                                                trigger={""}
                                                                                value={remboursement.prestatairePrescriptName}
                                                                                placeholder="Entrer un prestataire"
                                                                                onChange={val => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].prestatairePrescriptName = val;
                                                                                            let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                            linA.benefList[j].remboursements[k].prestatairePrescriptId = provider ? provider.id : null;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                                options={providers.map(prov => {return prov.providerName})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Centre exécutant</strong>
                                                                            </label>
                                                                            <TextInput
                                                                                className='form-control custom-text-input border'
                                                                                Component="input"
                                                                                required
                                                                                trigger={""}
                                                                                value={remboursement.prestataireExecName}
                                                                                placeholder="Entrer un prestataire"
                                                                                onChange={val => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].prestataireExecName = val;
                                                                                            let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                            linA.benefList[j].remboursements[k].prestataireExecId = provider ? provider.id : null;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                                options={providers.map(prov => {return prov.providerName})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Médecin</strong>
                                                                            </label>
                                                                            <input type='text' className='form-control'
                                                                                value={remboursement.medecin}
                                                                                required
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].medecin = e.target.value;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                                />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Montant réclamé</strong>
                                                                            </label>
                                                                            <input type='number' className='form-control'
                                                                                value={remboursement.montant}
                                                                                required
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].montant = parseInt(e.target.value);
                                                                                            calculateSums(l, j);
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                                />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Date de soin</strong>
                                                                            </label>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <DatePicker
                                                                                    autoOk
                                                                                    label=""
                                                                                    clearable
                                                                                    format="dd/MM/yyyy hh:mm"
                                                                                    value={remboursement.date}
                                                                                    disableFuture
                                                                                    required
                                                                                    onChange={(val) => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if(i === l) {
                                                                                                linA.benefList[j].remboursements[k].date = val;
                                                                                            }
                                                                                            return linA;
                                                                                        }))
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Fichiers({remboursement.files.length} chargés)</strong>
                                                                            </label>
                                                                            <div className='input-group'>
                                                                                <div className='custom-file'>
                                                                                    <input type='file' multiple onChange={(e) => saveFiles(e, i, j, k)} className='custom-file-input' />
                                                                                    <label className='custom-file-label'>Ajouter</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {k > 0 && <div className="col-lg-1">
                                                                        <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                            onClick={(e) => removeSubLine(e, i, j, k)}
                                                                        >
                                                                            <i className="fa fa-close"></i>
                                                                        </button>
                                                                    </div>}
                                                            </div>
                                                            
                                                            {remboursement.details.map((detail, m) => {
                                                                return <div className="row mb-2 ml-4" key={m}>
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Categorie</strong>
                                                                            </label>
                                                                            <Select
                                                                                className="select-search"
                                                                                value={garanties.filter(cat => {return cat.id === detail.categorieId})
                                                                                .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                                options={garanties.filter(cat => {return cat.id !== remboursement.categorieId})
                                                                                .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                                placeholder="Choisir"
                                                                                search
                                                                                isRequired
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].details[m].categorieId = val.value;
                                                                                            linA.benefList[j].remboursements[k].details[m].categorieName = val.label;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Centre exécutant</strong>
                                                                            </label>
                                                                            <TextInput
                                                                                className='form-control custom-text-input border'
                                                                                Component="input"
                                                                                required
                                                                                trigger={""}
                                                                                value={detail.prestataireExecName}
                                                                                placeholder="Entrer un prestataire"
                                                                                onChange={val => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].details[m].prestataireExecName = val;
                                                                                            let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                            linA.benefList[j].remboursements[k].details[m].prestataireExecId = provider ? provider.id : null;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                                options={providers.map(prov => {return prov.providerName})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Montant réclamé</strong>
                                                                            </label>
                                                                            <input type='number' className='form-control'
                                                                                value={detail.montant}
                                                                                required
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if(i === l) {
                                                                                            linA.benefList[j].remboursements[k].details[m].montant = e.target.value;
                                                                                            calculateSums(l, j);
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                                />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Date de soin</strong>
                                                                            </label>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <DatePicker
                                                                                    autoOk
                                                                                    label=""
                                                                                    clearable
                                                                                    format="dd/MM/yyyy hh:mm"
                                                                                    value={detail.date}
                                                                                    disableFuture
                                                                                    required
                                                                                    onChange={(val) => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if(i === l) {
                                                                                                linA.benefList[j].remboursements[k].details[m].date = val;
                                                                                            }
                                                                                            return linA;
                                                                                        }))
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Fichiers({detail.files.length} chargés)</strong>
                                                                            </label>
                                                                            <div className='input-group'>
                                                                                <div className='custom-file'>
                                                                                    <input type='file' multiple onChange={(e) => saveFiles(e, i, j, k, m)} className='custom-file-input' />
                                                                                    <label className='custom-file-label'>Ajouter</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-1">
                                                                        <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                            onClick={(e) => removeDetailsLine(e, i, j, k, m)}
                                                                        >
                                                                            <i className="fa fa-close"></i>
                                                                        </button>
                                                                    </div>
                                                            </div>})}
                                                            
                                                            <div className="d-flex justify-content-end">
                                                                <Button variant="danger" onClick={e => addDetailsLine(e, i, j, k)}>
                                                                    <i className="la la-plus"></i>
                                                                </Button>
                                                            </div>
                                                            <div className="border-top mt-2 mb-4"></div>
                                                            </>
                                                            })
                                                        }
                                                        <div className="d-flex justify-content-end">
                                                            <Button variant="secondary" onClick={e => addSubLine(e, i, j)}>
                                                                Ajouter un remboursement
                                                            </Button>
                                                        </div>
                                                        <div className="border-top mt-2 mb-4"></div>
                                                        </>
                                                        })}
                                                        
                                                        <div className="d-flex justify-content-end">
                                                            <Button variant="secondary" onClick={e => addPreSubLine(e, i)}>
                                                                Ajouter un bénéficiaire
                                                            </Button>
                                                        </div>
                                                        <div className="border-top border-primary mt-2 mb-4"></div>
                                                    </> :
                                                    <div></div>
                                                )
                                            })}

                                            {currentUser.isSouscripteur && <div className="col-lg-3 mb-2">
                                                <Button variant="primary" onClick={e => addLine(e)}>
                                                    Ajouter un assuré
                                                </Button>
                                            </div>}
                                        </section>
                                        
                                        <div className='row justify-content-center mt-4'>
                                            { showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">Un instant...</span>
                                                </Button> }
                                            { !showLoading &&
                                                <div className='col-4'>
                                                    <input type='submit' className='btn btn-primary btn-block'/>
                                                </div> }
                                        </div>
                                </form>
                            </>}
                       
                    </div>
                 </div>
              </div>
           </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providers,
        beneficiaires: state.contrat.beneficiaires,
        garanties: state.contrat.garanties,
        remboursement: state.facturation.remboursement,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(RemboursementRequest));