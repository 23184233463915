import React, {useState} from "react";
import { useDispatch } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import {format} from 'date-fns';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";

const RemboursementListItem = (props) => {
    const { t } = useTranslation();
    const { history, facture } = props;



    const dispatch = useDispatch();

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };

    const viewFacture = () => {
        history.push('/demande-remboursement-update/'+facture.id);
    }

    const printFacture = () => {
        history.push('/remboursement-details/'+facture.id);
    }

    const statusColor = () => {
        if(facture.status === STATUSES.pending) {
            return "text-warning"
        }
        if(facture.status === STATUSES.confirmed) {
            return "text-success"
        }
        if(facture.status === STATUSES.rejeted) {
            return "text-danger"
        }
        return "text-info"
    }


    return (
        <tr role="row" className="odd">
            <td className="patient_checkbox">
                <div className="checkbox text-right align-self-center">
                    <div className="custom-control custom-checkbox ">
                    <input
                        type="checkbox"
                        onClick={() => chackboxFun()}
                        className="custom-control-input"
                        id="customCheckBox1"
                        required
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                    />
                    </div>
                </div>
            </td>
            <td>{facture.id}</td>
            <td>{facture.name}</td>
            <td>{facture.montantTotal}</td>
            <td>{format(new Date(facture.dateFacturation), 'dd/MM/yyyy')}</td>
            <td>
                <div className="d-flex align-items-center">
                    <span className={statusColor()}>
                        {t('statuses.'+facture.statusPaiement)}
                    </span>
                </div>
            </td>
            <td>{facture.statusPaiement ? format(new Date(facture.datePaiement), 'dd/MM/yyyy') : ''}</td>
            <td className="d-flex justify-content-between align-items-center h100">
                <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={viewFacture}
                >
                    <i className="fa fa-eye"></i>
                </button>

                <button className="btn btn-warning shadow btn-xs sharp mr-2"
                    onClick={(e) => printFacture()}
                >
                    <i className="la la-print"></i>
                </button>
            </td>
        </tr>
    );
};

export default withRouter(RemboursementListItem);