import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { getFactureAction, startLoader } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import {format} from 'date-fns';
import {
  Dropdown
} from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { render } from "@testing-library/react";

class FactureTemplate extends React.Component {

    statusColor = () => {
        if(this.props.facture.status === "PENDING") {
            return "text-warning"
        }
        if(this.props.facture.status === "Non démarré") {
            return "text-secondary"
        }
        if(this.props.facture.status === "COMPLETE") {
            return "text-success"
        }
        if(this.props.facture.status === "Expiré") {
            return "text-danger"
        }
        return "text-danger"
    };

    groupElements = (arr) => {
        let nonExclu = arr.reduce((group, product) => {
            const { type } = product;
            if(!product.isRejet) {
                group[type] = group[type] ?? [];
                group[type].push(product);
            }
            return group;
        }, {});

        let exclus = arr.reduce((group, product) => {
            if(product.isRejet) {
                group["Exclusions"] = group["Exclusions"] ?? [];
                group["Exclusions"].push(product);
            }
            return group;
        }, {});

        return {...nonExclu, ...exclus};
    }

    totalexclusion = () => {
        return this.props.facture.facturePrestataireDetails ? this.props.facture.facturePrestataireDetails.reduce((partialSum, a) => a.isRejet ? partialSum + a.prixTotal : 0, 0) : 0;
    }

    render() {
        return (<div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-3">
                        <div className="card-header">
                            {" "}
                            <strong>{this.props.facture.name}</strong>{" "}
                            <span className="float-right">
                            <strong>Status:</strong> <span className={this.statusColor()}>{this.props.facture.statusPaiement ? this.props.facture.statusPaiement : "Impayée"}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            <div className="row mb-5">
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h6>De:</h6>
                                <div>
                                {" "}
                                <strong>{this.props.currentUser.provider.providerName}</strong>{" "}
                                </div>
                                <div>{this.props.currentUser.username}</div>
                                <div>{this.props.currentUser.provider.city?.name}, {this.props.currentUser.provider.country?.name}</div>
                                <div>Email: {this.props.currentUser.provider.email}</div>
                                <div>Phone: +{this.props.currentUser.provider.phoneNumber}</div>
                            </div>
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-end justify-content-end">
                                <h6>À:</h6>
                                <div>
                                {" "}
                                <strong>Activa Assurance</strong>{" "}
                                </div>
                                <div>Service Paiement</div>
                                <div>Rue Prince de Galles - Douala, Cameroun</div>
                                <div>Email: paiement@group-active.com</div>
                                <div>Phone: +237692880000</div>
                            </div>
                            </div>
                            <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="center">#</th>
                                        <th>Libelé</th>
                                        <th>Prestataire</th>
                                        <th className="right">P.U.</th>
                                        <th className="center">Qté</th>
                                        <th className="center">TM</th>
                                        <th className="right">À payer par l'assureur</th>
                                        <th className="right">Date</th>
                                    </tr>
                                </thead>
                                {this.props.facture.facturePrestataireDetails && <tbody>
                                    {Object.keys(this.groupElements(this.props.facture.facturePrestataireDetails)).map(keyfacture => { 
                                        return (
                                            <>
                                                <tr>
                                                    <th colSpan="8" className="center">
                                                        {keyfacture}
                                                    </th>
                                                </tr>
                                                {this.groupElements(this.props.facture.facturePrestataireDetails)[keyfacture].map((line, i) => {
                                                    return <tr>
                                                        <td className="center">{i}</td>
                                                        <td className="left strong">
                                                            {line.libelle}<br/>
                                                            {line.isRejet && <small className="text-danger">Exclu pour: {line.motifRejet}</small>}<br/>
                                                            {line.commentaireRejet && <small className="text-danger">{line.commentaireRejet}</small>}
                                                        </td>
                                                        <td className="right">{line.medecin}</td>
                                                        <td className="right">{line.prixUnitaire}</td>
                                                        <td className="center">{line.quantite}</td>
                                                        <td className="center">{line.priceToPayByPatient}</td>
                                                        <td className="right">{line.prixTotal}</td>
                                                        <td className="right">{format(new Date(line.dateExecution), 'dd/MM/yyyy')}</td>
                                                    </tr>
                                                })}
                                            </>
                                        )
                                    })
                                    }
                                </tbody>}
                            </table>
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-sm-5"> </div>
                            <div className="col-lg-4 col-sm-5 ml-auto">
                                <table className="table table-clear">
                                <tbody>
                                    <tr>
                                        <td className="left">
                                            <strong>Sous-total</strong>
                                        </td>
                                        <td className="right">
                                            {this.props.facture.montantTotal} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            <strong>Exclusions</strong>
                                        </td>
                                        <td className="right">{this.props.facture.facturePrestataireDetails?.reduce((partialSum, a) => partialSum + (a.isRejet ? a.prixTotal : 0), 0)}</td>
                                    </tr>
                                    <tr>
                                    <td className="left">
                                        <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                        <strong>{this.props.facture.montantTotal - (this.props.facture.facturePrestataireDetails?.reduce((partialSum, a) => partialSum + (a.isRejet ? a.prixTotal : 0), 0))}</strong>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
        );
    };

}

const FacturationDetails = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const {facture, currentUser} = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    
    useEffect(() => {
        dispatch(startLoader());
        dispatch(getFactureAction(id));
    }, []);


    return (
        <Fragment> 
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading && 
                <>
                    <div className="form-head d-flex mb-3 mb-md-4 align-items-end">
                        <div className="mr-auto d-none d-lg-block">
                            <button
                            onClick={e => handlePrint()}
                            className="btn btn-primary btn-rounded"
                            >
                            Imprimer
                            </button>
                        </div>
                    </div>

                    <FactureTemplate facture={facture} currentUser={currentUser} ref={componentRef} />
                </>
            }
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        facture: state.facturation.facture,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(FacturationDetails);
