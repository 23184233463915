import React, { Fragment, useState, useEffect, useMemo } from "react";
import {AzureMap,
        AzureMapDataSourceProvider,
        AzureMapFeature,
        AzureMapHtmlMarker,
        AzureMapLayerProvider,
        AzureMapsProvider,
        IAzureDataSourceChildren,
        IAzureMapFeature,
        IAzureMapHtmlMarkerEvent,
        IAzureMapLayerType,
        IAzureMapOptions,
} from 'react-azure-maps';
import {AuthenticationType, data, HtmlMarkerOptions, SymbolLayerOptions} from 'azure-maps-control';
import { GoogleMap, Marker, InfoWindow, useLoadScript, DirectionsRenderer } from "@react-google-maps/api";
import { connect } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { listProvidersAction } from '../../../../store/actions/ProviderActions';
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { errorToastr } from "../../../../Utils/ToastrMessage";
import widget8 from "../../../../images/task/img6.jpg";
import './ProviderMap.css';

export const ProviderMap = (props) => {
    const dispatch = useDispatch();
    
    const { currentUser, providers } = props;

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const [center, setCenter] = useState({});
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const [markers, setMarkers] = useState([]);
    const [directions, setDirections] = useState(null);

    const onMapLoad = (map) => {
        setMapRef(map);
        const bounds = new window.google.maps.LatLngBounds();
        markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        map.fitBounds(bounds);
    };

    const handleMarkerClick = (id, lat, lng, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, address });
        setIsOpen(true);

        if(id !== "user") {
            const DirectionsService = new window.google.maps.DirectionsService();
            DirectionsService.route({
                origin: new window.google.maps.LatLng(center.lat, center.lng),
                destination: new window.google.maps.LatLng(lat, lng),
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
            } else {
                errorToastr(`error fetching directions ${result}`);
            }
            });
        }
    };

    useEffect(() => {
        dispatch(listProvidersAction());

        navigator.geolocation.getCurrentPosition(function(position) {
            setCenter({lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude)});
        });
    }, []);

    useEffect(() => {
        setMarkers([...markers, ...providers.map((provider) => {
            if(provider.lat !== 0 && provider.lon !== 0) {
                return {
                    address: provider.providerName,
                    providerPhotoUrl: provider.providerPhotoUrl,
                    phoneNumber: provider.phoneNumber,
                    email: provider.email,
                    lat: provider.lat, 
                    lng: provider.lon
                }
            }
        }).filter(elt => {return elt})]);
    }, [providers]);

    /*const [markers, setMarkers] = useState([]);

    const [options, setOptions] = useState({
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: 'XtSIB5o0BVd_ZEREdsYSdzuR2PovZ_0NWHsK-96b3GY'
        },
        zoom: 12,
        view: 'Auto',
    });

    const [markersLayer] = useState('SymbolLayer'); 

    const [lineLayer] = useState('LineLayer');

    const [loadmap, setLoadMap] = useState(false);

    const points = [
        [9.6898425, 4.0620945],
        [9.6803771, 4.0556621]
    ]


    const memoizedOptions = {
        textOptions: {
          textField: ['get', 'title'], //Specify the property name that contains the text you want to appear with the symbol.
          offset: [0, 1.2],
        },
        strokeWidth: 5,
    };
      
    const [layerOptions, setLayerOptions] = useState(memoizedOptions);

    const addMarker = (lat, lon) => {
        const newPoint = new data.Position(lon, lat);
        setMarkers([...markers, newPoint]);
    };

    const onClick = (e) => {
    };

    const eventToMarker = [{ eventName: 'click', callback: onClick }];

    const clusterClicked = (e) => {
    }

    const azureHtmlMapMarkerOptions = (coordinates) => {
        return {
          position: coordinates,
          text: 'You are here',
          title: 'My Position',
        };
    }

    const  mouseOverLineString = (e) => {
    }

    useEffect(() => {
        dispatch(listProvidersAction());

        navigator.geolocation.getCurrentPosition(function(position) {
            setOptions({...options, center: [position.coords.longitude, position.coords.latitude]});
            setMarkers([...markers, {
                posData: new data.Position(position.coords.longitude, position.coords.latitude), 
                property: {
                    title: 'Vous',
                    icon: 'pin-round-red',
                }
            }]);
            setLoadMap(true);
            //setMarkers([...markers, new data.Position(9.6898425, 4.0620945)]);
        });
    }, []);

    useEffect(() => {
        setMarkers([...markers, ...providers.map((provider) => {
            if(provider.lat !== 0 && provider.lon !== 0) {
                return {
                    posData: new data.Position(provider.lon, provider.lat), 
                    property: {
                        title: provider.providerName,
                        icon: 'pin-round-blue',
                    }
                }
            }
        }).filter(elt => {return elt})]);
    }, [providers]);*/

    return (
        <div className="map-wrapper">
        {/*{loadmap && <AzureMapsProvider>

            <div className="row">
                <div className="col-xl-12 col-lg-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-0 d-flex justyfy-content-between">
                            <h4 className="fs-20 font-w600 mb-0">
                                Filtre
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div style={{ height: '300px', width: '100%' }}>
                    <AzureMap options={options} >
                        <AzureMapDataSourceProvider
                            events={{
                                dataadded: (e) => {
                                },
                            }}
                            id={'markersExample AzureMapDataSourceProvider'}
                            options={{ cluster: true, clusterRadius: 2, lineMetrics: true }}
                        >
                            <AzureMapLayerProvider
                                id={'markersExample AzureMapLayerProvider'}
                                options={layerOptions}
                                events={{
                                    click: clusterClicked,
                                    dbclick: clusterClicked
                                }}
                                lifecycleEvents={{
                                    layeradded: () => {
                                    },
                                }}
                                type={markersLayer}
                            />
                            { markers.map((marker, i) => { return <AzureMapFeature
                                key={i}
                                id={i.toString()}
                                type="Point"
                                coordinate={marker.posData}
                                properties={marker.property}
                            />}) }
                            
                            { markers.map((marker, i) => { return <AzureMapHtmlMarker
                                key={i}
                                markerContent={<div className="pulseIcon"></div>}
                                options={{ ...azureHtmlMapMarkerOptions(marker.posData) }}
                                events={eventToMarker}
                            />}) }

                            <AzureMapLayerProvider
                                id={'routeExample AzureMapLayerProvider'}
                                options={{
                                strokeWidth: 5,
                                strokeGradient: [
                                    'interpolate',
                                    ['linear'],
                                    ['line-progress'],
                                    0,
                                    'blue',
                                    0.1,
                                    'royalblue',
                                    0.3,
                                    'cyan',
                                    0.5,
                                    'lime',
                                    0.7,
                                    'yellow',
                                    1,
                                    'red',
                                ],
                                }}
                                events={{
                                    mouseenter: mouseOverLineString,
                                }}
                                lifecycleEvents={{
                                    layeradded: (e) => {
                                    },
                                }}
                                type={'LineLayer'}
                            />
                            <AzureMapFeature
                                key={'Line String Feature'}
                                id={'Line Strign ID'}
                                type={'LineString'}
                                coordinates={points}
                            />
                        </AzureMapDataSourceProvider>
                    </AzureMap>
                </div>
            </div>
                            </AzureMapsProvider>}*/}

            {(!isLoaded) ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                //zoom={700}
                onLoad={onMapLoad}
                onClick={() => setIsOpen(false)}
                >
                    <Marker
                    key="user"
                    position={center}
                    icon={"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"}
                    onClick={() => {
                        handleMarkerClick("user", center.lat, center.lng, "Vous êtes ici");
                    }}
                    >
                        {isOpen && infoWindowData?.id === "user" && (
                            <InfoWindow
                            onCloseClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <h3>{infoWindowData.address}</h3>
                            </InfoWindow>
                        )}
                    </Marker>
                {markers.map(({ address, lat, lng }, ind) => (
                    <Marker
                    key={ind}
                    position={{ lat, lng }}
                    onClick={() => {
                        handleMarkerClick(ind, lat, lng, address);
                    }}
                    >
                        {isOpen && infoWindowData?.id === ind && (
                            <InfoWindow
                            onCloseClick={() => {
                                setIsOpen(false);
                            }}
                            > 
                                    <div>
                                        {infoWindowData.providerPhotoUrl && 
                                            <img className="image-div" src={infoWindowData.providerPhotoUrl} alt={infoWindowData.address} />
                                        }
                                        {!infoWindowData.providerPhotoUrl && 
                                            <img className="image-div" src={widget8} alt={infoWindowData.address} />
                                        }<br/>
                                        <b className="text-dark">{infoWindowData.address}</b><br/>
                                        <small>Tel: {infoWindowData.phoneNumber}</small><br/>
                                        <small>Email: {infoWindowData.email}</small>
                                    </div>
                            
                            </InfoWindow>
                        )}
                    </Marker>
                ))}
                    {directions && <DirectionsRenderer directions={directions} />}
                </GoogleMap>
            )}
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providers,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(ProviderMap);