import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useParams, withRouter } from "react-router-dom";
import {getRemboursementAction, requestRemboursementAction, getMotifsExclusionAction, startLoader} from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import {format, min} from 'date-fns';
import {
  Dropdown
} from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { render } from "@testing-library/react";
import { Button, Modal } from "react-bootstrap";
import { formatDate } from "@fullcalendar/core";

class RemboursementTemplate extends React.Component {

    state = {
        currentKey: ''
    }
    

    statusColor = () => {
        if(this.props.facture.status === "PENDING") {
            return "text-warning"
        }
        if(this.props.facture.status === "Non démarré") {
            return "text-secondary"
        }
        if(this.props.facture.status === "COMPLETE") {
            return "text-success"
        }
        if(this.props.facture.status === "Expiré") {
            return "text-danger"
        }
        return "text-danger"
    };

    allTotals = (remb) => {
        return {
            exclusions: (remb.montantExclu ? remb.montantExclu : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.montantExclu ? subElt.montantExclu : 0), 0),
            baseRemb: (remb.baseRemboursement ? remb.baseRemboursement : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.baseRemboursement ? subElt.baseRemboursement : 0), 0),
            tm:  (remb.tm ? remb.tm : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.tm ? subElt.tm : 0), 0),
            totalRemb: (remb.montantRemb ? remb.montantRemb : 0) + remb.details.reduce((subSum, subElt) => subSum + (subElt.montantRemb ? subElt.montantRemb : 0), 0),
        }
    }

    render() {
        return (<div className="row">
                    <div className="col-lg-12">
                        <div className="card-header">
                            {" "}
                            <strong>{this.props.facture.name}</strong>{" "}
                            <span className="float-right">
                            <strong>Status:</strong> <span className="danger">{this.props.facture.status}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            <h3 className="justify-self-center text-center my-5 p-2 border boder-segondary">Bordereau de remboursement</h3>
                        </div>
                        {   this.props.theRemb.ligneAssures?.map(fact => {
                                return fact.benefList.map(benef => {
                                    return benef.remboursements.map(remb => {
                                        return (
                                            <div className="row">
                                                <div className="card mt-3">
                                                    <div className="card-body">
                                                        <h5 className="justify-self-center text-center my-5 p-2 border boder-segondary">Decompte de remboursement</h5>
                                                        <div className="row mb-5 border rounded">
                                                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                                <h6>Souscripteur: {this.props.facture.souscripteurName}</h6>
                                                                <div className="my-4">
                                                                    {" "}
                                                                    <strong>Numéro interne: {this.props.facture.souscripteurId}</strong>{" "}
                                                                </div>
                                                                <div className="my-4">Numéro police: {this.props.facture.numeroPolice}</div>
                                                                <div className="my-4">Aussuré: <b className="p-2 mx-2 border boder-segondary">{fact.assurePrincId}</b>{fact.assurePrincName}</div>
                                                                <div className="my-4">Bénéficiaire: <b className="p-2 mx-2 border boder-segondary">{benef.assureId}</b>{benef.assureName}</div>
                                                            </div>
                                                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-end justify-content-end">
                                                                <h6>Numéro de remboursement: <b className="p-2 mx-2 border boder-segondary">{remb.numRemboursement}</b></h6>
                                                                <div className="my-4">
                                                                {" "}
                                                                <strong>Date de demande: <b className="p-2 mx-2 border boder-segondary">{format(new Date(this.props.facture.dateFacturation), 'dd/MM/yyyy')}</b></strong>{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th className="center"><small>Date Soins</small></th>
                                                                    <th><small>N° Prestation</small></th>
                                                                    <th><small>Nomenclature</small></th>
                                                                    <th className="right"><small>Qté</small></th>
                                                                    <th className="right"><small>Montant Réclamé</small></th>
                                                                    <th className="right"><small>Montant Exclu</small></th>
                                                                    <th className="right"><small>Base de Remboursement</small></th>
                                                                    <th className="center"><small>Ticket Modérateur</small></th>
                                                                    <th className="right"><small>Montant Remboursé</small></th>
                                                                    <th className="right"><small>Libelé Exclusion</small></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className={remb.motifRejet ? 'bg-warning' : ''}>
                                                                    <td className="center"><small>{format(remb.date, 'dd/MM/yyyy')}</small></td>
                                                                    <td className="left strong">
                                                                        <small>
                                                                            {remb.categorieId}
                                                                        </small>
                                                                    </td>
                                                                    <td className="left strong"><small>{remb.categorieName}</small></td>
                                                                    <td className="right"><small>{remb.quantite}</small></td>
                                                                    <td className="right"><small>{remb.montant}</small></td>
                                                                    <td className="right"><small>{remb.montantExclu}</small></td>
                                                                    <td className="center"><small>{remb.baseRemboursement}</small></td>
                                                                    <td className="center"><small>{remb.tm}</small></td>
                                                                    <td className="center"><small>{remb.montantRemb}</small></td>
                                                                    <td className="right"><small>{remb.motifExclusion}</small></td>
                                                                </tr>
                                                                {remb.details.map((detail, i) => {
                                                                    return <tr className={detail.motifRejet ? 'bg-warning' : ''}>
                                                                        <td className="center"><small>{format(detail.date, 'dd/MM/yyyy')}</small></td>
                                                                        <td className="left strong">
                                                                            <small>
                                                                                {detail.categorieId}
                                                                            </small>
                                                                        </td>
                                                                        <td className="left strong"><small>{detail.categorieName}</small></td>
                                                                        <td className="right"><small>{detail.quantite}</small></td>
                                                                        <td className="right"><small>{detail.montant}</small></td>
                                                                        <td className="right"><small>{detail.montantExclu}</small></td>
                                                                        <td className="center"><small>{detail.baseRemboursement}</small></td>
                                                                        <td className="center"><small>{detail.tm}</small></td>
                                                                        <td className="center"><small>{detail.montantRemb}</small></td>
                                                                        <td className="right"><small>{detail.motifExclusion}</small></td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-lg-4 col-sm-5"> </div>
                                                        <div className="col-lg-4 col-sm-5 ml-auto">
                                                            <table className="table table-clear">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="left">
                                                                        <strong>Total Réclamé</strong>
                                                                    </td>
                                                                    <td className="right">{benef.montantReclame}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="left">
                                                                        <strong>Exclusions</strong>
                                                                    </td>
                                                                    <td className="right">{this.allTotals(remb).exclusions}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="left">
                                                                        <strong>Base Remb.</strong>
                                                                    </td>
                                                                    <td className="right">{this.allTotals(remb).baseRemb}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="left">
                                                                        <strong>Ticket Modérateur</strong>
                                                                    </td>
                                                                    <td className="right">{this.allTotals(remb).tm}</td>
                                                                </tr>
                                                                <tr>
                                                                <td className="left">
                                                                    <strong>Total Remboursé</strong>
                                                                </td>
                                                                <td className="right">
                                                                    <strong>{this.allTotals(remb).totalRemb}</strong>
                                                                </td>
                                                                </tr>
                                                            </tbody>
                                                            </table>
                                                        </div>
                                                        </div>
        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                } )
                            })
                        }
                    </div>
            </div>
        );
    };

}

const RemboursementDetails = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const {remboursement, history} = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const [theRemb, setTheRemb] = useState({});

    
    useEffect(() => {
        dispatch(startLoader());
        dispatch(getRemboursementAction(id));
        dispatch(getMotifsExclusionAction());
    }, []);

    useEffect(() => {
        if(Object.keys(remboursement).length > 0){
            let datas = {
                souscripteurId: remboursement.souscripteurId,
                souscripteurName: remboursement.souscripteurName,
            }

            let rembDetails = remboursement.factureRemboursementDetails;

            let res = [];

            let visitedIds = [];

            let myRes = rembDetails.map(remb => {
                let obj = {};
                if(!visitedIds.includes(remb.assurerPrincipalId)){
                    obj.assurePrincId = remb.assurerPrincipalId;
                    obj.assurePrincName = remb.assurerPrincipalName;
                    obj.montantReclame = remb.montantReclame;
                    let benefs = rembDetails.filter(elt => {return elt.assurerPrincipalId === remb.assurerPrincipalId});
                    
                    let visitedBenefIds = [];
                    obj.benefList = benefs.map(benef => {
                        let res = {};
                        if(!visitedBenefIds.includes(benef.assurerId)){
                            res.assureId = benef.assurerId;
                            res.assureName = benef.assurerName;
                            res.montantReclame = benef.montantReclame;
                            let presta = rembDetails.filter(elt => {return elt.assurerId === benef.assurerId});
                            
                            res.remboursements  = presta.map(prest => {
                                let resP = {};
                                if(prest.type && prest.type.toLowerCase().includes("consultation")) {
                                    
                                    resP.numRemboursement = prest.consultationCode;
                                    resP.prestatairePrescriptId = prest.prescripteurId;
                                    resP.prestatairePrescriptName = prest.prescripteurName;
                                    resP.prestataireExecId = prest.executeurId;
                                    resP.prestataireExecName = prest.executeurName;
                                    resP.categorieId = prest.categorieId;
                                    resP.categorieName = prest.categorieName;
                                    resP.montant = prest.montantPrestationReclame;
                                    resP.medecin = prest.prescripteurName;
                                    resP.montantExclu = prest.montantExclu;
                                    resP.motifExclusion = prest.motifExclu;
                                    resP.baseRemboursement = prest.factureRejets.reduce((sum, elt) => sum + elt.baseRemb, 0);
                                    resP.montantRemb = prest.montantPrestationReclame - prest.montantExclu;
                                    resP.tm = prest.factureRejets.reduce((sum, elt) => sum + elt.tm, 0);
                                    resP.quantite = prest.quantite;
                                    resP.date = new Date(prest.dateExecution);
                                    resP.actes = prest.factureRejets;
                                    resP.files = [prest.files];
                                    let detPrest = rembDetails.filter(elt => {return (elt.consultationCode === prest.consultationCode && !elt.type)})
                                    
                                    resP.details = detPrest.map(detP => {
                                        return {
                                            prestataireExecId: detP.executeurId,
                                            prestataireExecName: detP.executeurName,
                                            categorieId: detP.categorieId,
                                            categorieName: detP.categorieName,
                                            montant: detP.montantPrestationReclame,
                                            date: new Date(detP.dateExecution),
                                            montantExclu: detP.montantExclu,
                                            motifExclusion: detP.motifExclu,
                                            baseRemboursement: detP.factureRejets.reduce((sum, elt) => sum + elt.baseRemb, 0),
                                            montantRemb: detP.montantPrestationReclame - detP.montantExclu,
                                            tm: detP.factureRejets.reduce((sum, elt) => sum + elt.tm, 0),
                                            actes: detP.factureRejets,
                                            quantite: detP.quantite,
                                            files: [detP.files]
                                        }
                                    });
                                }
                                return resP;
                            });
                            res.remboursements = res.remboursements.filter(elt => {return Object.keys(elt).length > 0});
                            visitedBenefIds.push(benef.assurerId);
                        }
                        return res;
                    });
                    obj.benefList = obj.benefList.filter(elt => {return Object.keys(elt).length > 0});
                    visitedIds.push(remb.assurerPrincipalId);
                }
                return obj;
            });

            datas.ligneAssures = myRes.filter(elt => {return Object.keys(elt).length > 0});
            
            setTheRemb(datas);
        }
    }, [remboursement])


    return (
        <Fragment> 
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading && 
                <>
                    <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                        <div className="mr-auto d-none d-lg-block">
                            <button
                            onClick={e => handlePrint()}
                            className="btn btn-primary btn-rounded mr-3"
                            >
                            Imprimer
                            </button>
                        </div>
                    </div>

                    <RemboursementTemplate theRemb={theRemb} facture={remboursement} ref={componentRef} dispatch={dispatch} />
                </>
            }
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        motifExclusion: state.facturation.motifExclusion,
        remboursement: state.facturation.remboursement,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(RemboursementDetails));
