import {getConsultations, getConsultation, requestConsultation, searchDrugs, searchDiseases, getSuggestion, updateConsultation, getClientConsultations, getClientMedicalFile, getParameterHistory,
     requestAppointment, listAppointments, getAppointment, getActesConsultation} from '../../services/ConsultationService';
import { getPatientResultsFiles } from '../../services/LaboratoireService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_CONSULTATIONS_CONFIRMED_ACTION = '[getConsultations action] confirmed getConsultations';
export const GET_CONSULTATIONS_FAILED_ACTION = '[getConsultations action] failed getConsultations';

export const GET_CONSULTATION_DATA_CONFIRMED_ACTION = '[getConsultation action] confirmed getConsultation';
export const GET_CONSULTATION_DATA_FAILED_ACTION = '[getConsultation action] failed getConsultation';

export const REQUEST_CONSULTATION_CONFIRMED_ACTION = '[requestConsultation action] confirmed requestConsultation';
export const REQUEST_CONSULTATION_FAILED_ACTION = '[requestConsultation action] failed requestConsultation';


export const SEARCH_DRUGS_CONFIRMED_ACTION = '[searchDrugs action] confirmed searchDrugs';
export const SEARCH_DRUGS_FAILED_ACTION = '[searchDrugs action] failed searchDrugs';


export const SEARCH_DISEASES_CONFIRMED_ACTION = '[searchDiseases action] confirmed searchDiseases';
export const SEARCH_DISEASES_FAILED_ACTION = '[searchDiseases action] failed searchDiseases';


export const GET_SUGGESTIONS_CONFIRMED_ACTION = '[getSuggestion action] confirmed getSuggestion';
export const GET_SUGGESTIONS_FAILED_ACTION = '[getSuggestion action] failed getSuggestion';

export const UPDATE_CONSULTATION_CONFIRMED_ACTION = '[updateConsultation action] confirmed updateConsultation';
export const UPDATE_CONSULTATION_FAILED_ACTION = '[updateConsultation action] failed updateConsultation';

export const GET_CLIENT_CONSUTATIONS_CONFIRMED_ACTION = '[getClientConsultations action] confirmed getClientConsultations';
export const GET_CLIENT_CONSUTATIONS_FAILED_ACTION = '[getClientConsultations action] failed getClientConsultations';

export const GET_CLIENT_MEDICAL_FILE_CONFIRMED_ACTION = '[getClientMedicalFile action] confirmed getClientMedicalFile';
export const GET_CLIENT_MEDICAL_FILE_FAILED_ACTION = '[getClientMedicalFile action] failed getClientMedicalFile';

export const GET_PARAMS_HISTORY_CONFIRMED_ACTION = '[getParamsHistory action] confirmed getParamsHistory';
export const GET_PARAMS_HISTORY_FAILED_ACTION = '[getParamsHistory action] failed getParamsHistory';

export const REQUEST_APPOINTMENT_CONFIRMED_ACTION = '[requestAppointment action] confirmed requestAppointment';
export const REQUEST_APPOINTMENT_FAILED_ACTION = '[requestAppointment action] failed requestAppointment';

export const LIST_APPOINTMENTS_CONFIRMED_ACTION = '[listAppointments action] confirmed listAppointments';
export const LIST_APPOINTMENTS_FAILED_ACTION = '[listAppointments action] failed listAppointments';

export const GET_APPOINTMENT_CONFIRMED_ACTION = '[getAppointment action] confirmed getAppointment';
export const GET_APPOINTMENT_FAILED_ACTION = '[getAppointment action] failed getAppointment';

export const START_LOADER_ACTION = '[providerLoader action] started providerLoader';
export const START_SECOND_LOADER_ACTION = '[providerSecondLoader action] started providerSecondLoader';

export const GET_ACTES_CONSULTATION_CONFIRMED_ACTION = '[getActesConsultation action] confirmed getActesConsultation';
export const GET_ACTES_CONSULTATION_FAILED_ACTION = '[getActesConsultation action] failed getActesConsultation';

var I18n = require('react-redux-i18n').I18n;

export const getConsultationsAction = (providerId, patientId = null) => {
    
    return (dispatch) => {
        getConsultations(providerId)
        .then((response) => {
            
            dispatch(confirmedGetConsultationsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getConsult.error')+error.response?.data?.message);
            dispatch(getConsultationsFailedAction(error.response?.data?.message));
        });
    };
};

export const getConsultationDataAction = (id) => {
    
    return (dispatch) => {
        getConsultation(id)
        .then((response) => {
            
            dispatch(confirmedGetConsultationAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getConsultData.error')+error.response?.data?.message);
            dispatch(getConsultationFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};


export const makeConsultationRequest = (data, history) => {
    
    return (dispatch) => {
        requestConsultation(data)
        .then((response) => {
            
            dispatch(confirmedRequestConsultationAction());
            history.push('/consultation-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.makeConsult.error')+error.response?.data?.message);
            dispatch(requestConsultationFailedAction(error.response?.data?.message));
        });
    };
}

export const searchDrugsAction = (keyword) => {
    
    return (dispatch) => {
        searchDrugs(keyword)
        .then((response) => {
            
            dispatch(confirmedSearchDrugsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.searchDrug.error')+error.response?.data?.message);
            dispatch(searchDrugsFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};

export const searchDiseasesAction = (keyword) => {
    
    return (dispatch) => {
        searchDiseases(keyword)
        .then((response) => {
            
            dispatch(confirmedSearchDiseasesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.searchDisease.error')+error.response?.data?.message);
            dispatch(searchDiseasesFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};


export const getSuggestionsDataAction = (type) => {
    
    return (dispatch) => {
        getSuggestion(type)
        .then((response) => {
            
            dispatch(confirmedGetSuggestionsAction(response.data, type));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getSuggest.error')+error.response?.data?.message);
            dispatch(getSuggestionsFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};

export const updateConsultationAction = (data, history) => {
    
    return (dispatch) => {
        updateConsultation(data)
        .then((response) => {
            
            localStorage.removeItem("consultation")
            dispatch(confirmedUpdateConsultationAction());
            history.push('/consultation-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.updateConsult.error')+error.response?.data?.message);
            dispatch(updateConsultationFailedAction(error.response?.data?.message));
        });
    };
}

export const getClientConsultationsAction = (patientId) => {
    
    return (dispatch) => {
        getClientConsultations(patientId)
        .then((response) => {
            
            dispatch(confirmedGetClientConsultationsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getClientConsult.error')+error.response?.data?.message);
            dispatch(getClientConsultationsFailedAction(error.response?.data?.message));
        });
    };
};

export const getClientMedicalFileAction = (patientId) => {
    return (dispatch) => {
        getClientMedicalFile(patientId)
        .then((response) => {
            
            let ans = response.data;
            getPatientResultsFiles(patientId)
            .then((resp) => {
                ans.analyseFiles = resp.data;
                dispatch(confirmedGetClientMedicalFileAction(ans));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.medicalFile.error_one')+error.response?.data?.message);
                dispatch(getClientClientMedicalFileFailedAction(error.response?.data?.message));
            });
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.medicalFile.error_two')+error.response?.data?.message);
            dispatch(getClientClientMedicalFileFailedAction(error.response?.data?.message));
        });
    };
};

export const getParametersHistoryAction = (startDate, endDate) => {
    
    return (dispatch) => {
        getParameterHistory(startDate, endDate)
        .then((response) => {
            
            dispatch(confirmedGetParamsHistoryAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getPatientParam.error')+error.response?.data?.message);
            dispatch(getParamsHistoryFailedAction(error.response?.data?.message));
        });
    };
};

export const makeAppointmentRequest = (data, history) => {
    
    return (dispatch) => {
        requestAppointment(data)
        .then((response) => {
            
            dispatch(confirmedRequestAppointmentAction());
            history.push('/appointments-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.makeAppointment.error')+error.response?.data?.message);
            dispatch(requestAppointmentFailedAction(error.response?.data?.message));
        });
    };
}

export const getAppointmentsAction = (id) => {
    
    return (dispatch) => {
        listAppointments(id)
        .then((response) => {
            
            dispatch(confirmedlistAppointmentsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getPatientAppointment.error')+error.response?.data?.message);
            dispatch(listAppointmentsFailedAction(error.response?.data?.message));
        });
    };
};

export const getAppointmentDataAction = (id, history) => {
    
    return (dispatch) => {
        getAppointment(id)
        .then((response) => {
            
            dispatch(confirmedGetAppointmentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getPatientAppointment.error_data')+error.response?.data?.message);
            dispatch(getAppointmentFailedAction(error.response?.data?.message));
            history.goBack();
        });
    };
};


export const getActesConsultationAction = () => {
    
    return (dispatch) => {
        getActesConsultation()
        .then((response) => {
            
            dispatch(confirmedGetActesConsultationAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getActesConsult.error')+error.response?.data?.message);
            dispatch(getActesConsultationFailedAction(error.response?.data?.message));
        });
    };
 };

 export function confirmedGetActesConsultationAction(payload) {
    return {
        type: GET_ACTES_CONSULTATION_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getActesConsultationFailedAction(payload) {
    return {
        type: GET_ACTES_CONSULTATION_FAILED_ACTION,
        payload,
    };
 };

export function confirmedGetConsultationsAction(payload) {
    return {
        type: GET_CONSULTATIONS_CONFIRMED_ACTION,
        payload,
    };
};

export function getConsultationsFailedAction(payload) {
    return {
        type: GET_CONSULTATIONS_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetConsultationAction(payload) {
    return {
        type: GET_CONSULTATION_DATA_CONFIRMED_ACTION,
        payload,
    };
};

export function getConsultationFailedAction(payload) {
    return {
        type: GET_CONSULTATION_DATA_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};

export function startSecondLoader() {
    return {
        type: START_SECOND_LOADER_ACTION
    };
};

export function confirmedRequestConsultationAction() {
    return {
        type: REQUEST_CONSULTATION_CONFIRMED_ACTION
    };
};

export function requestConsultationFailedAction(payload) {
    return {
        type: REQUEST_CONSULTATION_FAILED_ACTION,
        payload,
    };
};

export function confirmedSearchDrugsAction(payload) {
    return {
        type: SEARCH_DRUGS_CONFIRMED_ACTION,
        payload,
    };
};

export function searchDrugsFailedAction(payload) {
    return {
        type: SEARCH_DRUGS_FAILED_ACTION,
        payload,
    };
};

export function confirmedSearchDiseasesAction(payload) {
    return {
        type: SEARCH_DISEASES_CONFIRMED_ACTION,
        payload,
    };
};

export function searchDiseasesFailedAction(payload) {
    return {
        type: SEARCH_DISEASES_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetSuggestionsAction(data, typeData) {
    return {
        type: GET_SUGGESTIONS_CONFIRMED_ACTION,
        payload: {data, typeData},
    };
};

export function getSuggestionsFailedAction(payload) {
    return {
        type: GET_SUGGESTIONS_FAILED_ACTION,
        payload,
    };
};

export function confirmedUpdateConsultationAction() {
    return {
        type: UPDATE_CONSULTATION_CONFIRMED_ACTION
    };
};

export function updateConsultationFailedAction(payload) {
    return {
        type: UPDATE_CONSULTATION_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetClientConsultationsAction(payload) {
    return {
        type: GET_CLIENT_CONSUTATIONS_CONFIRMED_ACTION,
        payload,
    };
};

export function getClientConsultationsFailedAction(payload) {
    return {
        type: GET_CLIENT_CONSUTATIONS_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetClientMedicalFileAction(payload) {
    return {
        type: GET_CLIENT_MEDICAL_FILE_CONFIRMED_ACTION,
        payload,
    };
};

export function getClientClientMedicalFileFailedAction(payload) {
    return {
        type: GET_CLIENT_MEDICAL_FILE_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetParamsHistoryAction(payload) {
    return {
        type: GET_PARAMS_HISTORY_CONFIRMED_ACTION,
        payload,
    };
};

export function getParamsHistoryFailedAction(payload) {
    return {
        type: GET_PARAMS_HISTORY_FAILED_ACTION,
        payload,
    };
};

export function confirmedRequestAppointmentAction() {
    return {
        type: REQUEST_APPOINTMENT_CONFIRMED_ACTION
    };
};

export function requestAppointmentFailedAction(payload) {
    return {
        type: REQUEST_APPOINTMENT_FAILED_ACTION,
        payload,
    };
};

export function confirmedlistAppointmentsAction(payload) {
    return {
        type: LIST_APPOINTMENTS_CONFIRMED_ACTION,
        payload,
    };
};

export function listAppointmentsFailedAction(payload) {
    return {
        type: LIST_APPOINTMENTS_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetAppointmentAction(payload) {
    return {
        type: GET_APPOINTMENT_CONFIRMED_ACTION,
        payload,
    };
};

export function getAppointmentFailedAction(payload) {
    return {
        type: GET_APPOINTMENT_FAILED_ACTION,
        payload,
    };
};