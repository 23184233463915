import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import './EtatsGlobaux.css';

const EtatsTopConsommations = (props) => {
    const { t } = useTranslation();
    const {data, type, showLoading} = props;

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);

    useEffect(() => {
        if(data.length > 0){
            setStatsDatas(data.map((key, i) => {
                return {
                    id: i,
                    statName: key.name,
                    totalMontant: key["total"]["montant"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["montant"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["montant"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),
                    children: key.children?.map((child, j) => {
                        return {
                            id: j,
                            statName: child.name,
                            totalMontant: child["total"]["montant"],
                            totalPourcent: parseFloat(child["total"]["taux"]),
                            ambuMontant: child["ambulatoire"]["montant"],
                            ambuPourcent: parseFloat(child["ambulatoire"]["taux"]),
                            hospiMontant: child["hospitalier"]["montant"],
                            hospiPourcent: parseFloat(child["hospitalier"]["taux"])
                        }
                    })
                }
            })) 

            let prestsAmbu = data.filter(elt => elt.ambulatoire.montant > 0);
            let prestsHospi = data.filter(elt => elt.hospitalier.montant > 0);
            
            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["montant"]
                })
            }]);
            
            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["montant"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.name));
            setDataHospiLabels(prestsHospi.map(key => key.name));
        }
    }, [data]);

    const rowExpansionTemplate = (data) => {
        const childHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Bénéficiaire" rowSpan={2}/>
                    <Column header="Total" colSpan={2} />
                    <Column header="Ambulatoire" colSpan={2} />
                    <Column header="Hospitalier" colSpan={2} />
                </Row>
                <Row>
                    <Column header="Montant" field="totalMontant" />
                    <Column header="Taux en %" field="totalPourcen" />
                    <Column header="Montant" field="ambuMontant" />
                    <Column header="Taux en %" field="ambuPourcen" />
                    <Column header="Montant" field="hospiMontant" />
                    <Column header="Taux en %" field="hospiPourcen" />
                </Row>
            </ColumnGroup>
        );
        
        return (
            <div>
                <DataTable 
                    dataKey="id"
                    showGridlines 
                    headerColumnGroup={childHeaderGroup}
                    value={data.children}
                >
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>
            </div>
        );
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={type} rowSpan={2} colSpan={type === "Famille" ? 2 : 1} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Montant" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Montant" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Montant" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    const allowExpansion = (rowData) => {
        return rowData.children && rowData.children.length > 0;
    };

    const onRowExpand = (event) => {
    };

    const onRowCollapse = (event) => {
    };

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && <div className='results-section'>
                <div className='row justify-content-center my-4'>
                    <div className='form-group mb-0'>
                        <label className='radio-inline mr-5'>
                            <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => {setGraph(!e.target.checked)}}/> Données
                        </label>
                        <label className='radio-inline'>
                            <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => {setGraph(e.target.checked)}}/> Graphique
                        </label>
                    </div>
                </div>
                {!graph && <DataTable 
                    value={statsDatas} 
                    dataKey="id"
                    showGridlines 
                    headerColumnGroup={headerGroup} 
                    tableStyle={{ minWidth: '50rem' }}
                    expandedRows={expandedRows}
                    onRowExpand={onRowExpand} 
                    onRowCollapse={onRowCollapse} 
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                >
                    {type === "Famille" && <Column expander={allowExpansion} style={{ width: '5rem' }} />}
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>}
                {graph && 
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{type === 'Actes' ? 'Actes en Ambulatoire' : 'Consommateurs en Ambulatoire'}</h5>
                            <HorizontalBarChart series={dataAmbuSeries} labels={dataAmbuLabels} height="150" />
                        </div>
                        <div className='col-6'>
                            <h5>{type === 'Actes' ? 'Actes en Hospitalisation' : 'Consommateurs en Hospitalisation'}</h5>
                            <HorizontalBarChart series={dataHospiSeries} labels={dataHospiLabels} height="150" />
                        </div>
                    </div>
                }
            </div>}
        </>
    )
}
export default EtatsTopConsommations;