import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { currentUser } from '../../../../../store/selectors/CurrentUserSelector';
import MOCK_DATA from './MOCK_DATA_2.json';
import { COLUMNS } from './Columns';
import './filtering.css';

const PiecesJointes = (props) => {
    const { datas } = props;
    const dispatch = useDispatch();

    const buildDataSet = (infos) => {
        return infos.files?.map((file, i) => {
            return {
                id: i,
                message: file.message,
                file: file.docUrl,
                send_at: new Date(),
            }
        })
    }

	const columns = useMemo( () => COLUMNS, [] );
	const data = buildDataSet(datas);

	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination);

    const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

    
	const {globalFilter, pageIndex} = state


    return (
        <Fragment>
            <div className="row">
                <div className='col-12'>
                    <div className='card' style={{width: "100%" }}>
                        <div className='card-header border-0 bg-primary'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-paperclip mx-1' style={{fontSize: "25px"}}></i>
                                Pièces Jointes
                            </h4>
                            <div className="input-group search-area d-inline-flex" style={{width: "40%"}}>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                value={globalFilter || ''}  
                                onChange={e => setGlobalFilter(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="input-group-text">
                                        <i className="la la-search" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="table-responsive">
                                <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                    <thead>
                                    {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps()}>
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                            </tr>
                                    ))}
                                    </thead> 
                                    <tbody {...getTableBodyProps()} className="" >
                                    
                                        {page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{''}
                                    </span>
                                    <span className="table-index">
                                        Go to page : {' '}
                                        <input type="number" 
                                            className="ml-2"
                                            defaultValue={pageIndex + 1} 
                                            onChange = {e => { 
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                                                gotoPage(pageNumber)
                                            } } 
                                        />
                                    </span>
                                </div>
                                <div className="text-center">	
                                    <div className="filter-pagination  mt-3">
                                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                        
                                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(PiecesJointes);