import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    getUserDatas,
    updatePassword
} from '../../services/AuthService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const GET_USER_DATAS_CONFIRMED_ACTION = '[getUserDatas action] confirmed getUserDatas';
export const GET_USER_DATAS_FAILED_ACTION = '[getUserDatas action] failed getUserDatas';

var I18n = require('react-redux-i18n').I18n;

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/');
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function preLoginAction(email, password) {
    return (dispatch) => {
        login(email, password)
            .then((res) => {
                let response = {
                    data: {
                        message: "Welcome "+res.data.username, 
                        statusCode: "LOGIN-SUCCESS", 
                        token: res.data.token,
                        expiresIn: 60000,
                        id: res.data.id,
                        patientId: res.data.patientId,
                        username: res.data.username,
                        isSouscripteur: res.data.contrat?.IsSouscripteur,
                        isAssurePrinc: res.data.contrat?.IsBenePrin,
                        police: res.data.contrat?.police,
                        garanties: res.data.contrat?.garanties,
                        college: res.data.contrat?.college,
                        souscripteur: res.data.contrat?.souscripteur,
                        beneficiaire: res.data.contrat?.beneficiaire,
                        email: res.data.email,
                        roles: res.data.role,
                        profils: res.data.profils,
                        menus: res.data.menus,
                        clientName: res.data.clientName,
                        clientId: res.data.clientId,
                        polices: res.data.polices,
                        code: res.data.verificationCode,
                        isCodeLogged: false
                    }
                };
                
                dispatch(loginConfirmedAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('auth.error')+error.response?.data?.message);
                dispatch(loginFailedAction(error.response?.data?.message));
            });
    };
}

export function loginAction(data, history) {
    return (dispatch) => {
        saveTokenInLocalStorage(data);
        runLogoutTimer(
            dispatch,
            data.expiresIn * 1000,
            history,
        );
        
        successToastr(I18n.t('auth.welcome')+data.username);
        dispatch(loginConfirmedAction(data));

        if(data.clientId && data.polices){
            history.push('/assures/liste');
        }else{
            history.push('/appointments-list');
        }

        window.location.reload();
    };
}

export function updatePasswordAction(data, history) {
    return (dispatch) => {
        updatePassword(data)
            .then((res) => {
                dispatch(logout(history));
                successToastr(I18n.t('auth.updatePass.success'));
            })
            .catch((error) => {
                errorToastr(I18n.t('auth.updatePass.error')+error.response?.data?.message);
                dispatch(loadingToggleAction(false));
            });
    };
}


export function getUserDatasAction(id) {
    return (dispatch) => {
        getUserDatas(id)
        .then((response) => {
            dispatch(confirmedGetUserDatasAction(response.data));
        })
        .catch((error) => {
            dispatch(getUserDatasFailedAction(error.response?.data?.message));
        });
    };
}

export function confirmedGetUserDatasAction(payload) {
    return {
        type: GET_USER_DATAS_CONFIRMED_ACTION,
        payload,
    };
}

export function getUserDatasFailedAction(message) {
    return {
        type: GET_USER_DATAS_FAILED_ACTION,
        payload: message,
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
