import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { requestAvenantAction, loadingToggleAction } from "../../../store/actions/ContratActions";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from 'react-select';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from "react-i18next";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const ChangementCollegeModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sexes = [
        {label: "Masculin", value: "M"},
        {label: "Féminin", value: "F"}
    ];
    const { police, currentUser, assure, colleges, showLoading } = props;
    const [collegeId, setCollegeId] = useState('');
    const [prevCollegeId, setPrevCollegeId] = useState(assure.numeroCollege || '');

    const onSave = (e) => {
        e.preventDefault();

        let data = {
            codeAssu: currentUser.clientId,
            numeroPolice: police,
            newCollege: collegeId,
            oldCollege: prevCollegeId,
            numeroBeneficiaire: assure.numBeneficiaire
        }
        dispatch(loadingToggleAction(true));
        dispatch(requestAvenantAction(data, 'changementCollege'));
    }

    return (
        <form onSubmit={e => onSave(e)}>
            <p>Vous souhaitez demander un changement de collège pour l'assuré {assure.nom}</p>
            <div className='row my-2 align-items-center'>
                <div className="col-6">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Collège Actuel</strong>
                            <span className='text-danger ml-1'>*</span>
                        </label>
                        <Select
                            className="select-search"
                            value={colleges?.filter(elt => elt.numeroCollege === prevCollegeId).map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})}
                            options={[{value: '', label: "Choisir"}, ...colleges?.map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})]}
                            placeholder="Choisir un collège"
                            search
                            
                            onChange={(val) => {
                                setPrevCollegeId(val.value);
                            }}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Nouveau Collège</strong>
                            <span className='text-danger ml-1'>*</span>
                        </label>
                        <Select
                            className="select-search"
                            value={colleges?.filter(elt => elt.numeroCollege === collegeId).map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})}
                            options={[{value: '', label: "Choisir"}, ...colleges?.map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})]}
                            placeholder="Choisir un collège"
                            search
                            
                            onChange={(val) => {
                                setCollegeId(val.value);
                            }}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-4'>
                { showLoading && 
                    <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />{' '}
                        <span className="visually-hidden">Un instant...</span>
                    </Button> }
                { !showLoading &&
                    <div className='col-4'>
                        <input type='submit' className='btn btn-primary btn-block'/>
                    </div> }
            </div>
        </form>  
    )
};

const mapStateToProps = (state) => {
    return {
        showLoading: state.contrat.showLoading
    };
};

export default connect(mapStateToProps)(ChangementCollegeModal);