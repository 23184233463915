import React, { useRef }from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import "primeicons/primeicons.css";
import { InputSwitch } from 'primereact/inputswitch';
        

export const DataTableBase = (props) => {
    const dt = useRef(null);
    const {data, emptyMessage, filters, globalFiltersFields, header, columns,
         rows, loading, rowClass, size, showExport, exportFilename,
         exportColumns, lazy, dataSet, onGoToPage, onSortData, onFilterData,
         totalRecords, removableSort, rowClick, selectedData, 
         setSelectedDatas, isRowSelectable } = props;
    
    /*const exportColumns = columns.map((col) => {
        if(col.headerName !== 'Actions'){
            return { title: col.headerName, dataKey: col.name }
        }
    }).filter(elt => elt);*/

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, data);
                doc.save(exportFilename+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, exportFilename);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };


    const exportButtons = showExport && data.length > 0 ? (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
    ) : (<></>);

    return (
        <DataTable 
            ref={dt}
            value={data} 
            paginator 
            size={size}
            //showGridlines
            rowClassName={rowClass} 
            rows={rows} 
            loading={loading} 
            dataKey="id" 
            filters={lazy ? dataSet?.filters : filters}
            globalFilterFields={globalFiltersFields} 
            header={header}
            footer={exportButtons}
            emptyMessage={emptyMessage}
            removableSort={removableSort}
            lazy={lazy}
            first={lazy ? dataSet?.first : 0}
            totalRecords={totalRecords}
            onPage={onGoToPage}
            onSort={onSortData} 
            onFilter={onFilterData}
            sortField={dataSet?.sortField} 
            sortOrder={dataSet?.sortOrder}
            selectionMode={rowClick ? null : 'checkbox'} 
            selection={selectedData} 
            onSelectionChange={rowClick ? (e) => setSelectedDatas(e.value) : null}
            isDataSelectable={rowClick ? (e) => isRowSelectable(e.data) : null}
            scrollable
            scrollHeight="400px"
        >
            {
                columns.map((column, index) => {
                    return <Column 
                        key={index}
                        field={column.name} 
                        dataType={column.dataType}
                        header={column.headerName} 
                        filter={column.filter} 
                        filterField={column.filterField}
                        filterPlaceholder={column.filterPlaceholder} 
                        filterElement={column.filterElement}
                        style={column.style} 
                        body={column.bodyTemplate}
                        bodyClassName={column.bodyClassName}
                        sortable={column.sortable}
                        selectionMode={column.selectionMode}
                    />
                })
            }
        </DataTable>
    )
};

export default DataTableBase;